import { api } from "jiffy-api";
import { isStyleVisible } from "common/utils/ui/element";
import { chunkArray } from "common/utils/array";
import { isElementVisible } from "common/utils/ui/visibility";

const SELECTORS = {
  DELIVERY_SPINNER: ".js-plp-delivery-spinner",
  DELIVERY_DATE: ".js-plp-delivery-date",
  DELIVERY_CONTAINER: ".js-plp-dates-container",
  DELIVERY_TIMELEFT: ".js-plp-delivery-timeleft"
};

const SAME_DAY_DELIVERY_DATE = "Today, by 9 PM";

// Global variable is used to sync different js chunks that call this module
const compositionIdsSent = window?.jiffy?.ui?.productsCompositionIdsSent || [];

/* eslint-disable no-unused-vars */
const trackDeliveryDatesRendered = (renderTime, ajaxTime, additionalTags = {}) => {
  // track("delivery_dates_rendered", {
  //   page: window?.jiffy?.ui?.productsRenderPage || "other",
  //   time_to_get_date: Math.floor(ajaxTime),
  //   time_to_render_date: Math.floor(renderTime),
  //   ...additionalTags
  // });
  // TODO: cleanup code related to delivery_dates_rendered calculations
  // Cart: https://github.com/sdtechdev/spree-jiffyshirts/pull/33466 , https://github.com/sdtechdev/spree-jiffyshirts/pull/33581
  // PDP: https://github.com/sdtechdev/spree-jiffyshirts/pull/33379
  // PLP: https://github.com/sdtechdev/spree-jiffyshirts/pull/33623
};

const isSpinnerHidden = $el => {
  const $spinner = $el.querySelector(".js-plp-delivery-spinner");

  // Need the negated result
  return !isStyleVisible($spinner);
};

const getCompositionIds = rows =>
  Array.from(rows)
    .filter(cell => cell.dataset.assignable === "true")
    .reduce(
      (acct, element) => {
        const { compositionId: id, sameDayCutoff, made } = element.dataset;
        if (compositionIdsSent.includes(id)) return acct;

        if (sameDayCutoff) {
          acct.sameDayCutoffIds.push(id);
        } else if (made === "true") {
          acct.madeCompositionIds.push(id);
        } else {
          acct.regularCompositionIds.push(id);
        }
        return acct;
      },
      {
        regularCompositionIds: [],
        sameDayCutoffIds: [],
        madeCompositionIds: []
      }
    );

const renderEstimates = ({ compositionId, date, oneShipDay, timeleft, showTimeleft }) => {
  const render = $container => {
    if (!date) {
      $container.querySelector(SELECTORS.DELIVERY_SPINNER).classList.add("hidden");
      $container.dataset.assignable = false;
      return;
    }

    $container
      .querySelector(SELECTORS.DELIVERY_DATE)
      .classList.toggle("product-card__delivery-date-cart--tomorrow", !!oneShipDay);

    $container.querySelector(SELECTORS.DELIVERY_DATE).setAttribute("data-date", date);
    $container.querySelector(SELECTORS.DELIVERY_DATE).setAttribute("data-timeleft", timeleft);
    $container.querySelector(SELECTORS.DELIVERY_DATE).innerHTML = date;
    $container.querySelector(SELECTORS.DELIVERY_CONTAINER).classList.remove("hidden");
    $container.querySelector(SELECTORS.DELIVERY_SPINNER).classList.add("hidden");

    const $timeleft = $container.querySelector(SELECTORS.DELIVERY_TIMELEFT);
    if (showTimeleft && timeleft && $timeleft) {
      $timeleft.innerHTML = timeleft;
    }
  };

  const $allContainers = document.querySelectorAll(
    `.product-card__delivery-row[data-composition-id="${compositionId}"]`
  );

  Array.from($allContainers).forEach(render);
};

const fetchCompositionEstimates = (compositionIds, isMade) =>
  new Promise((resolve, reject) => {
    const requestStartTime = performance?.now() || 0;
    api.product
      .fetchCompositionEstimates({
        composition_ids: compositionIds.join(),
        made: isMade ? "true" : undefined
      })
      .then(data => {
        const requestEndTime = performance?.now() || 0;
        resolve([data, requestEndTime - requestStartTime]);
      })
      .catch(reject);
  });

const renderSameDayCompositions = rawCompositionIds => {
  const compositionIds = [...new Set(rawCompositionIds)];

  Array.from(compositionIds).forEach(compositionId => {
    const $sameDayContainer = document.querySelector(
      `.product-card__delivery-row[data-composition-id="${compositionId}"]`
    );
    const { sameDayCutoff: timeleft } = $sameDayContainer.dataset;
    renderEstimates({
      compositionId,
      date: SAME_DAY_DELIVERY_DATE,
      oneShipDay: true,
      showTimeleft: true,
      timeleft
    });
  });
};

export const loadCompositionEstimates = (rawCompositionIds, isMade = false) => {
  const compositionIds = [...new Set(rawCompositionIds)];
  return fetchCompositionEstimates(compositionIds, isMade).then(([data, ajaxTime]) => {
    if (data.success) {
      Array.from(compositionIds).forEach(compositionId => {
        const { date, timeleft, one_ship_day: oneShipDay } = data.estimates[compositionId] || {};
        renderEstimates({ compositionId, date, oneShipDay, timeleft });
      });
    }

    return [data, ajaxTime];
  });
};

export const loadVisibleCompositionEstimates = () => {
  const renderStartTime = performance?.now() || 0;
  const estimatesRows = document.querySelectorAll(".js-plp-product-card-delivery");

  const visibleRows = Array.from(estimatesRows).filter(
    element => isElementVisible(element) && !isSpinnerHidden(element)
  );

  const { regularCompositionIds, madeCompositionIds, sameDayCutoffIds } =
    getCompositionIds(visibleRows);

  [
    [regularCompositionIds, false],
    [madeCompositionIds, true]
  ].forEach(([compositionIds, isMade]) => {
    if (compositionIds.length) {
      compositionIdsSent.push(...compositionIds);

      loadCompositionEstimates(compositionIds, isMade).then(([_data, ajaxTime]) => {
        const renderEndTime = performance?.now() || 0;
        trackDeliveryDatesRendered(renderEndTime - renderStartTime, ajaxTime, {
          render_trigger: "scroll"
        });
      });
    }
  });

  if (sameDayCutoffIds.length) {
    compositionIdsSent.push(...sameDayCutoffIds);
    renderSameDayCompositions(sameDayCutoffIds);
  }
};

const getVisibleItemsCount = $estimatesRows => {
  const visibleItemsCount = Array.from($estimatesRows).filter(
    $el => isElementVisible($el) && !isSpinnerHidden($el)
  ).length;
  return visibleItemsCount === 0 ? 2 : visibleItemsCount;
};

export const loadFirstFewRowsEstimates = () => {
  const $estimatesRows = document.querySelectorAll(".js-plp-product-card-delivery");

  const visibleItemsCount = getVisibleItemsCount($estimatesRows);

  // All with visible spinners but not necessarily visible in current viewport
  const $estimateableRows = Array.from($estimatesRows).filter($el => !isSpinnerHidden($el));

  // Get two packs of visibleItemsCount in a query that receives only 2 items at a time
  // For example, if we visibleItemsCount is 5 (desktop), we will get 5 queries with 2 items each
  // If visibleItemsCount is 2 (mobile), we will get 2 queries with 2 items each
  const $batches = chunkArray($estimateableRows, 2).slice(0, visibleItemsCount);

  $batches.forEach($batch => {
    const { regularCompositionIds, madeCompositionIds, sameDayCutoffIds } =
      getCompositionIds($batch);
    // Launch each as promise, so no need to wait to execute one after the other.
    // The first call will ideally finish first.
    [
      [regularCompositionIds, false],
      [madeCompositionIds, true]
    ].forEach(([compositionIds, isMade]) => {
      if (compositionIds.length) {
        compositionIdsSent.push(...compositionIds);

        loadCompositionEstimates(compositionIds, isMade).then(([_data, ajaxTime]) => {
          const renderStartTime = window?.jiffy?.timers?.productsRenderStartTime || 0;
          const renderEndTime = performance?.now() || 0;
          trackDeliveryDatesRendered(renderEndTime - renderStartTime, ajaxTime, {
            render_trigger: "load"
          });
        });
      }
    });

    if (sameDayCutoffIds.length) {
      compositionIdsSent.push(...sameDayCutoffIds);
      renderSameDayCompositions(sameDayCutoffIds);
    }
  });
};
