import { api } from "jiffy-api";
import { track } from "jiffy-analytics";
import { redirectTo } from "common/utils/browser";
import "./same-day-delivery-checker.scss";

const CLASSES = {
  HIDDEN: "hidden",
  CHECKER: {
    EMPTY: "same-day-delivery-checker--success"
  },
  MESSAGE: {
    SUCCESS: "same-day-delivery-checker__message--success",
    ERROR: "same-day-delivery-checker__message--error"
  }
};

class SameDayDeliveryChecker {
  constructor(options) {
    this.options = options;
    this.init();
  }

  init() {
    this.handleSubmit = this.handleSubmit.bind(this);

    if (this.$form) {
      this.$form.addEventListener("submit", this.handleSubmit);

      if (!this.zipcode) {
        this.$form.classList.add(CLASSES.CHECKER.EMPTY);
      }
    }
  }

  get $form() {
    return document.querySelector(this.options.formSelector);
  }

  get $message() {
    return document.querySelector(this.options.messageSelector);
  }

  get $loader() {
    return document.querySelector(this.options.loaderSelector);
  }

  get $action() {
    return document.querySelector(this.options.actionSelector);
  }

  get zipcode() {
    return new FormData(this.$form).get("zip");
  }

  get redirectToUrl() {
    return this.$form.dataset.redirectTo;
  }

  set message(value) {
    if (value && this.$message) {
      const isError = value.type === "error";

      this.$message.classList.remove(CLASSES.HIDDEN);
      this.$message.innerHTML = value.text;

      this.$message.classList.toggle(CLASSES.MESSAGE.SUCCESS, !isError);
      this.$message.classList.toggle(CLASSES.MESSAGE.ERROR, isError);
    } else if (this.$message) {
      this.$message.classList.add(CLASSES.HIDDEN);
    }
  }

  set isLoading(value) {
    if (value) {
      this.$form.classList.remove(CLASSES.CHECKER.EMPTY);
      this.$loader.classList.remove(CLASSES.HIDDEN);
      this.$action.disabled = true;
    } else {
      this.$loader.classList.add(CLASSES.HIDDEN);
      this.$action.disabled = false;
    }
  }

  async handleSubmit(event) {
    event.preventDefault();

    try {
      this.message = null;
      this.isLoading = true;

      track("flash_plp_zipcode_entered", { zip_code: this.zipcode });

      const { error, message: errorMessage } = await api.location.update({
        zipcode: this.zipcode
      });

      if (error) {
        this.message = {
          text: errorMessage || "Please enter a valid zipcode",
          type: "error"
        };
      } else {
        redirectTo(this.redirectToUrl);
      }
    } catch (error) {
      this.message = {
        text: error.message || "Something went wrong...",
        type: "error"
      };
    } finally {
      this.isLoading = false;
    }
  }
}

const init = () =>
  new SameDayDeliveryChecker({
    formSelector: ".js-same-day-delivery-checker",
    messageSelector: ".js-same-day-delivery-checker-message",
    loaderSelector: ".js-same-day-delivery-checker-loader",
    actionSelector: ".js-same-day-delivery-checker-action"
  });

export default init;
