/* eslint-disable max-lines-per-function */
import { sendDataLayer } from "jiffy-analytics";
import { isTouchDevice } from "common/utils/browser";
import { cacheDeliveryEstimate } from "components/jiffy/product-details/cache-composition-delivery-date";
import "components/jiffy/product-details-dtf-sheet/product-details-dtf-amounts/product-details-dtf-amounts.scss";
import "./product-card.scss";

const SELECTORS = {
  CARD: ".js-product-card",
  HEART: ".product-card__has-favorite",
  SHIPPER: ".js-ships"
};

const displayFavorites = productCard => {
  if (!window.favorite_composition_ids) return;

  const { compositionId } = productCard.dataset;
  const isFavorite = window.favorite_composition_ids.includes(parseInt(compositionId, 10));

  const heart = productCard.querySelector(SELECTORS.HEART);
  if (!heart) return;

  const isHidden = heart.classList.contains("hidden");

  if (isFavorite && isHidden) {
    heart.classList.remove("hidden");
  } else if (!isFavorite && !isHidden) {
    heart.classList.add("hidden");
  }
};

const shouldHandleShipperClick = eventTarget =>
  !isTouchDevice() &&
  (eventTarget.closest(SELECTORS.SHIPPER) || eventTarget.classList.contains(SELECTORS.SHIPPER));

const handleTooltip = () => {
  $(".js-ships-tooltip")
    .off("inserted.bs.tooltip")
    .on("inserted.bs.tooltip", event => {
      const $$tooltipLink = $(event.currentTarget);
      const tooltipId = $$tooltipLink.attr("aria-describedby");
      const $tooltipElem = document.getElementById(tooltipId);
      const hideTooltip = e => {
        if (
          e.type === "click" &&
          (e.composedPath().includes($tooltipElem) || e.composedPath().includes($$tooltipLink[0]))
        )
          return;

        $tooltipElem.classList.remove("show");
        $$tooltipLink.tooltip("hide");
      };

      if ($tooltipElem.classList.contains("show")) return;

      // Hide other tooltips before showing the current one
      $(".js-ships-tooltip")
        .not($$tooltipLink)
        .each((_, $element) => {
          const $$element = $($element);
          const otherTooltipId = $$element.attr("aria-describedby");
          const $otherTooltipElem = document.getElementById(otherTooltipId);
          if (!$otherTooltipElem) return;

          $otherTooltipElem.classList.remove("show");
          $$element.tooltip("hide");
        });

      $tooltipElem.classList.add("show");
      $$tooltipLink.on("keydown", hideTooltip);
      $tooltipElem.addEventListener("mouseleave", hideTooltip);
      document.addEventListener("scroll", hideTooltip);
      document.addEventListener("click", hideTooltip, true);

      $$tooltipLink.on("hide.bs.tooltip", e => {
        if ($tooltipElem.classList.contains("show")) {
          e.preventDefault();
        } else {
          $$tooltipLink.off("keydown");
          $tooltipElem.removeEventListener("mouseleave", hideTooltip);
          document.removeEventListener("scroll", hideTooltip);
          document.removeEventListener("click", hideTooltip, true);
          $$tooltipLink.off("hide.bs.tooltip");
        }
      });
    });
};

export default () => {
  const productCards = document.querySelectorAll(SELECTORS.CARD);

  productCards.forEach($productCard => {
    $productCard.addEventListener("click", e => {
      const { gaLabel: gaEventLabel, badges, name, amount, currency } = $productCard.dataset;
      const $productItem = $productCard.closest(".js-products__item");
      const { gaList = "" } = $productItem ? $productItem.dataset : {};
      cacheDeliveryEstimate($productCard);

      if (shouldHandleShipperClick(e.target)) {
        const { shipperPath, shipperLabel } = $productCard.querySelector(SELECTORS.SHIPPER).dataset;

        if (shipperPath) {
          sendDataLayer({
            genericEvent: true,
            eventCategory: "shipperclick",
            event: "click",
            eventLabel: shipperLabel
          });

          window.location.href = shipperPath;

          return;
        }
      }

      if (gaEventLabel) {
        const path = gaEventLabel.split(":");
        const catalogNumber = path.pop();
        sendDataLayer({
          event: "SelectItem",
          ecommerce: {
            items: [
              {
                item_id: catalogNumber,
                item_name: name,
                price: amount,
                currency
              }
            ],
            item_list_name: gaList
          }
        });

        sendDataLayer({
          genericEvent: true,
          eventCategory: "productclick",
          event: "click",
          eventLabel: gaEventLabel
        });

        if (badges) {
          JSON.parse(badges).forEach(badgeName => {
            sendDataLayer({
              genericEvent: true,
              eventCategory: "UX",
              event: "Badge Viewed",
              eventLabel: `${gaEventLabel}:${badgeName}`
            });
          });
        }
      }
    });

    handleTooltip();
    displayFavorites($productCard);
  });

  document.querySelectorAll(".product-card__explanation").forEach(explanation => {
    explanation.addEventListener("click", _e => {
      /* eslint-disable no-console */
      console.log(JSON.parse(explanation.dataset.explanation));
      /* eslint-enable no-console */
    });
  });
};
