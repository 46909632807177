/* eslint-disable max-lines-per-function */
import { STORE_BASE_PATH } from "common/utils/config";

let loadedDates = true;
let loadedPrices = false;

const clearLoadingState = () => {
  if (!loadedDates || !loadedPrices) return;

  const $domColors = document.querySelectorAll(".js-color-variant-selector");

  $domColors.forEach(domColor => {
    domColor.classList.remove("product-color-picker__item--loading");
  });

  loadedPrices = false;
  loadedDates = true;
};

export const shownPricesForUSA = STORE_BASE_PATH !== "/ca";

export const fillMinimalPricesPCP = data => {
  const prices = data.min_prices_for_colors;

  if (!prices || !shownPricesForUSA) return;

  const colors = Object.keys(prices);
  colors.forEach(color => {
    const $pricePlaces = document.querySelectorAll(
      `.js-composition-minimal-amount[data-composition-name="${color}"]`
    );

    $pricePlaces.forEach($pricePlace => {
      const price = parseFloat(prices[color]);

      if (price) {
        $pricePlace.innerHTML = `$${price.toFixed(2)}`;
      }
    });
  });

  loadedPrices = true;
  clearLoadingState();
};

export const fillDeliverDatesPCP = dates => {
  dates.forEach(date => {
    const $datePiecePlace = document.querySelector(
      `.js-composition-delivery-date[data-composition-name="${date.group_name}"][data-shop-by="piece"]`
    );
    const $dateCasePlace = document.querySelector(
      `.js-composition-delivery-date[data-composition-name="${date.group_name}"][data-shop-by="case"]`
    );
    const pieceDate = date.dates.jiffy_first || date.dates.regular;
    const caseDate = date.cased_variants_dates.jiffy_first || date.cased_variants_dates.regular;

    if (pieceDate && $datePiecePlace) {
      $datePiecePlace.innerHTML = pieceDate.substring(0, pieceDate.length - 6);
    }

    if (caseDate && $dateCasePlace) {
      $dateCasePlace.innerHTML = caseDate.substring(0, caseDate.length - 6);
    }
  });

  loadedDates = true;
  clearLoadingState();
};

export default {};
